import { apiRoutes } from "../../config"
import { api } from "../../utils/lib/api"

export const accInstapago = async (method,ent, data) =>{
    try {
        if(method === "POST"){
            const url = `${apiRoutes.instapago.acc}/${ent}/acc/add`
            return (await api.post(url, data)).data
        }
        if(method === "GET"){
            const url = `${apiRoutes.instapago.acc}/${ent}/acc/${data ? data : ''}`
            return (await api.get(url)).data
        }
    } catch (error) {
        console.error(error)
        return error
    }
}

export const generateTempUrl = async (ent, data) =>{
    try {
        const url = `${apiRoutes.instapago.pay}/${ent}/generate-temp-url`;
        return (await api.post(url, {...data})).data
    } catch (error) {
        console.log(error)
    }
}

export const getOrderData = async (orderId)=>{
    const url = `${apiRoutes.instapago.pay}/order/${orderId}`
    try {
        return (await api.get(url))?.data.data;
    } catch (error) {
        console.log(error)   
    }
}

export const getPayLink = async (orderId) =>{
    const url = `${apiRoutes.instapago.pay}/get-link/order/${orderId}`
    try {
        return (await api.get(url))?.data.data;
    } catch (error) {
        console.error(error)
    }
}
export const generateQrPay = async(entId, orderId, data)=>{
    const url = `${apiRoutes.instapago.pay}/${entId}/order/${orderId}/generate-qr`
    try {
        return (await api.post(url, {...data}))?.data.data;
    } catch (error) {
        console.error(error)
    }
}

export const getPaymentInfoClient = async(orderId, data)=>{
    const url = `${apiRoutes.instapago.paymentInfo}`
    try {
        return (await api.get(url))?.data.data;
    } catch (error) {
        console.error(error)
    }
}

export const verifyMobilePayment = async(entId, orderId, data, payType)=>{
    const url = `${apiRoutes.instapago.pay}/${entId}/${orderId}/verify/${payType}`;
    try {
        return (await api.post(url, {...data}))?.data.data;
    } catch (error) {
        console.error(error)
    }
}

export const getBanksAvailable = async()=>{
    const url = `${apiRoutes.instapago.banksAvailable}`;
    try {
        return (await api.get(url))?.data.data;
    } catch (error) {
        console.error(error)
    }
}

