import React from 'react'
import './instapagostStyle.css'

function ThanksForPay() {
  return (
    <div className='thanks-for-pay-container d-flex flex-column justify-content-center align-items-center'>
        <h2>Gracias por preferirnos</h2>

        <h5 className='mt-3 mb-5'>Su pago sido confirmado de forma exitosa</h5>
        <div className='thanks-for-pay-image'>
            <img className='uix-pay-vector' src={require('../../../../assets/img/png/thanks_for_pay.png')}/>
        </div>

        <p>Si tienes alguna duda o sugerencia comunicate con nosotros a travez de nuestro boton directo a whatsapp o haciendo clic <span><a href=''>aqui</a></span></p>
    </div>
  )
}

export default ThanksForPay