import React from 'react'

function OrderClientData({numOrder, customerInfo}) {
  return (
    <div>
        <div className="container" style={{maxWidth:1280}}>
            <h4 className='text-center mb-4' style={{color:'#424242'}}>Datos de la orden {numOrder}</h4>
            <div className="row" style={{rowGap:13}}>
                <div className="input-group col-12 col-md-6 px-0">
                    <span className="input-group-text">Numero de orden</span>
                    <input type="text" className="form-control" disabled value={numOrder}/>
                </div>
                <div className="input-group col-12 col-md-6 px-0">
                    <span className="input-group-text">Nombre y Apellido</span>
                    <input type="text" className="form-control" disabled value={customerInfo.name}/>
                </div>
                <div className="input-group col-12 col-md-6 px-0">
                    <span className="input-group-text">{customerInfo.l10n_latam_identification_type_id[1]}</span>
                    <input type="text" className="form-control" disabled value={customerInfo.vat}/>
                </div>
                <div className="input-group col-12 col-md-6 px-0">
                    <span className="input-group-text">Telefono</span>
                    <input type="text" className="form-control" disabled value={customerInfo.phone}/>
                </div>
                <div className="input-group col-12 col-md-6 px-0">
                    <span className="input-group-text">Estado</span>
                    <input type="text" className="form-control" disabled value={customerInfo.state_id[1]}/>
                </div>
                <div className="input-group col-12 col-md-6 px-0">
                    <span className="input-group-text">Dirección</span>
                    <input type="text" className="form-control" disabled value={customerInfo.street}/>
                </div>
                <div className="col-12 col-lg-6"></div>
            </div>
        </div>
  </div>
  )
}

export default OrderClientData