import React, { useContext, useState } from 'react';
import OrderClientData from './OrderClientData';
import ProductClientData from './ProductClientData';
import { faSearch, faCopy } from '@fortawesome/free-solid-svg-icons'
import { getOrderData } from '../../../../hooks/api/odoo';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import './instapagostStyle.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { generateTempUrl, getPayLink } from '../../../../hooks/api/instapago';
import PopUp from '../../../general/popUps/PopUp';

function InstapagoMobilePayment() {
  const [preNumOrder, setPreNumOrder] = useState('')
  const [numOrder, setNumOrder] = useState('')
  const [orderData, setOrderData] = useState([])
  const [loading, setLoading] = useState(false)
  const [linkPay, setLinkPay] = useState('')
  const [linkActive, setLinkActive] = useState(false)
  const [tasaBancaria, setTasaBancaria] = useState(0)
  const globalContext = useContext(GlobalContext);
  const [showPopUp, setShowPopUp] = useState(false)
  const [popUpData, setPopUpData] = useState({message: "", action: "", status: "" });
  
  const search = async () =>{
    setLoading(true);
    setLinkActive(false)
    const entId = await globalContext.currentEnterpriseId;
    if(!preNumOrder){
      setLoading(false)
      return
    }
    try {
      const getData = await getOrderData(entId, preNumOrder);
      if (!getData) {
          setShowPopUp(true)
          setPopUpData({
            message: 'No se pudo obtener datos de la orden',
            action: 'Lo sentimos, no se ha podido obtener los datos de la orden', 
            status: 'error'
          })
          setLoading(false);
          return;
      }
      
      setNumOrder(preNumOrder);
      setOrderData(getData);
      setTasaBancaria(getData.tazaCambio)
      const orderidParser = preNumOrder.replaceAll("/", "-")
      const payLink = await getPayLink(orderidParser);
      const url = `${window.location.origin}/${payLink.payLink}`;
      setLinkPay(url)
      if(payLink.payLink) setLinkActive(true)

    } catch (error) {
      console.error('Error al obtener datos:', error);
    } finally {
      setLoading(false);
    }
  }

  const createPayLink = async () =>{
    const entId = await globalContext.currentEnterpriseId;
    const getStatus = await generateTempUrl(entId, orderData)
    const orderidParser = preNumOrder.replaceAll("/", "-")
    const payLink = await getPayLink(orderidParser);
    const url = `${window.location.origin}/${payLink.payLink}`;
    
    setLinkPay(url)
    setLinkActive(true)
    setShowPopUp(true)
    setPopUpData({
      message: getStatus?.message,
      action: 'Has creado un link de pago', 
      status: 'success'
    })

  }

  const copyLink = async () =>{
      await navigator.clipboard.writeText(linkPay);
      alert('Link de pago copiado!')
  }

  return (
    <div className=''>
      {
        showPopUp && (
          <PopUp setShow={setShowPopUp} message={popUpData.message} action={popUpData.action} status={popUpData.status} />
        )
      }
        <div className="py-2 px-3" style={{backgroundColor: '#014788'}}>
            <h1 className="text-white display-5 text-center m-0">Nueva orden</h1>
        </div>
        {
          tasaBancaria > 0 && (
            <div>
                <h5 style={{fontSize:'.85rem', textAlign:'center', transform:'translateY(10px)'}}>Tasa bancaria del dia: {tasaBancaria}</h5>
            </div>
          )
        }
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className='d-flex flex-column flex-md-row justify-content-center' style={{gap:15, maxWidth:850, margin:'auto', marginTop:50}}>
                <div className={`input-group container-search justify-content-center ${Object.keys(orderData).length > 0 && !loading && 'active'}`}>
                  <span className="input-group-text" id="basic-addon3">Numero de orden</span>
                  <input type="text" className="form-control" style={{maxWidth:300}} value={preNumOrder} onChange={((v)=>setPreNumOrder(v.target.value))}/>
                  <div className={`d-flex align-items-center justify-content-center px-4 text-white search`} style={{cursor:'Pointer', backgroundColor: '#014788'}} onClick={()=>search()}>
                    <FontAwesomeIcon icon={faSearch}/>
                  </div>

                </div>
                {
                  Object.keys(orderData).length > 0 && !loading &&(
                    <div className='d-flex justify-content-end w-100' style={{gap:15}}>
                      <button className="btn btn-danger" onClick={()=>createPayLink()} disabled={linkActive}>Crear link de pago</button>
                    </div>
                  )
                }
              </div>
            </div>
            {Object.keys(orderData).length > 0 && !loading && (
              <>
                  {
                    linkActive === true && (
                      <div className='col-12 d-flex justify-content-center align-items-center mt-4'>
                      <span className="input-group-text">Link de pago</span>
                      <input type="text" className="form-control w-100" id='link-pay' disabled style={{maxWidth:400,}} value={linkPay}/>
                      <FontAwesomeIcon icon={faCopy} color='#014788' id='icon-copy' onClick={()=>copyLink()}/>
                  </div>
                    )
                  }
                  <div className="col-12 mt-5">
                  <div>
                    <OrderClientData numOrder={numOrder} customerInfo={orderData.customerInfo}/>
                  </div>
                </div>
                <div className="col-12 px-0">
                  <ProductClientData productData={orderData.billLines} saleOrderData={orderData.billDetails} tasaBancaria={tasaBancaria}/>
                </div>
              </>
            ) 
          
          }
          {
            loading && (
              <div className='col-12 d-flex justify-content-center align-items-center' style={{height: 'calc(100vh - 400px)'}}>
                <div className="loader"></div>
              </div>
            )
          }
          </div>
        </div>
    </div>
  )
}
export default InstapagoMobilePayment   
