import { apiRoutes } from "../../config";
import { api } from "../../utils/lib/api";

export const getOrderData = async (entId,numOrder)=>{
    const url = `${apiRoutes.odoo.getOrderData}/${entId}/bill-info`
    try {
        return (await api.post(url,{billName: numOrder}))?.data.data;
    } catch (error) {
        console.log(error)   
    }
}

export const consolidationWithOdoo = async (data)=>{
    const url = `${apiRoutes.odoo.getOrderData}/5f1378dcef2f752aeda16673/odoo/create-payment`;
    try {
        return (await api.post(url,{...data}))?.data.data;
    } catch (error) {
        console.log(error)   
    }
}