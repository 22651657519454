import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { getBanksAvailable } from '../../../../hooks/api/instapago';
import './instapagostStyle.css';

function ValidateMobilePayment({amount, setPaymentDataPay, setBankValue, payType, setDocType, docType}) {
    const [banksAvailables, setBanksAvailables] = useState(null);
    const [formData, setFormData] = useState({
        reference: "",
        phonenumberclient: "",
        clientId: "",
        bank: "",
        date: "",
        amount: 0,
      });
      
      const docTypes = [
        { value: "V", label: "V" },
        { value: "E", label: "E" },
        { value: "J", label: "J" },
        { value: "G", label: "G" }
      ];
      const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
      const handleSelectChange = (selectedOption) => {
        setFormData({ ...formData, bank: selectedOption });
        setBankValue(`0${selectedOption.value}`)
      };

      const getBanksAvailableFn = async ()=>{
        const banks = (await getBanksAvailable())?.bcvBankInfos;
        if(!banks) return;
        
        const options = banks.map(rt => ({
          value: rt.serialNumber,
          label: rt.name,
        }));
        setBanksAvailables(options)
      }      

      useEffect(()=>{
        setPaymentDataPay(formData)
      }, [formData])

      useEffect(()=>{
        getBanksAvailableFn()
      }, [])
  return (
    <div>
    <div className="container mb-4">
      <div className="row" style={{ gap: 20 }}>
      <div className="input-group col-12 col-md-6 px-0">
          <span className="input-group-text">Documento</span>
            <div style={{width: 70}}>
              <Select
                id="docType"
                value={docType}
                onChange={(selected) => setDocType(selected)}
                options={docTypes}
                placeholder=''
                isClearable={false}
                />
              </div>
            <input
              type="text"
              className="form-control"
              name="clientId"
              value={formData.clientId}
              onChange={handleInputChange}
            />
        </div>
        <div className="input-group col-12 col-md-5 px-0">
          <span className="input-group-text">Referencia</span>
          <input
            type="text"
            className="form-control"
            name="reference"
            value={formData.reference}
            onChange={handleInputChange}
          />
        </div>
        {
          payType === 'mobilePayment' && (
            <div className="input-group col-12 col-md-5 px-0">
              <span className="input-group-text">Teléfono</span>
              <input
                type="text"
                className="form-control"
                name="phonenumberclient"
                value={formData.phonenumberclient}
                onChange={handleInputChange}
              />
            </div>
          )
        }
        <div className="input-group col-12 col-md-6 px-0">
          <span className="input-group-text">Banco</span>
          <div style={{ width: "calc(100% - 71.74px)" }}>
            <Select
              id="bank"
              value={banksAvailables?.find(option => option.value === formData.bank?.value) || null}
              onChange={handleSelectChange}
              options={banksAvailables}
              isClearable={false} 
            />
          </div>
        </div>
        <div className="input-group col-12 col-md-5 px-0">
          <span className="input-group-text">Fecha</span>
          <input
            type="date"
            className="form-control"
            name="date"
            value={formData.date}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group col-12 col-md-5 px-0">
          <span className="input-group-text">Monto pagado</span>
          <input
            type="number"
            className="form-control"
            name="amount"
            value={formData.amount}
            onChange={handleInputChange}
          />
          <p
            className="mt-1 ms-1"
            style={{ color: "#a0a0a0", marginTop: ".8rem", marginLeft: 7 }}
          >
            Monto esperado ({amount})
          </p>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ValidateMobilePayment