import React, { useCallback, useContext, useEffect, useState } from "react";
import PedidosYaStyle from "../../../../assets/css/components/admin/plugins/PedidosYaStyle";
import { Redirect } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import verifyAccessRole from "../../../../assets/js/verifyAccessRole";
import Routes from "../../../../Routes";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import OdooStyle from "../../../../assets/css/components/admin/plugins/OdooStyle.css";
import View from "./view";
import { FaPlus } from "react-icons/fa";
import { Button } from "../../../../assets/css/components/admin/marketplace-to-marketplace/filters/FiltersStyles";
import  InstapagoCredentials from "./InstapagoCredentials";
import { accInstapago } from "../../../../hooks/api/instapago";

export default function InstaPagoConfig() {
  const [redirect, setRedirect] = useState(null);
  const globalContext = useContext(GlobalContext);
  const [selectedMarketplace, setSelectedMarketplace] = useState(null);
  const [loading, setLoading] = useState(null);
  const [step, setStep] = useState(0);
  const [modalNewCredential, setModalNewCredential] = useState(false);
  const [store, setStore] = useState(null);
  const [listUsers, setListUsers] = useState([]);

  const changeStep = (number) => {
    if (step !== 0) setStep(number);
  };
  const handleSubmit = useCallback((value) => {
    if (value && value !== selectedMarketplace) {
      setSelectedMarketplace(value);
      setStore(value);
      console.log("Cuenta seleccionada:", value);
    }
  }, [selectedMarketplace]);

  const handleModal = () => {
    setModalNewCredential(true);
  };

  // Función para obtener los username desde la API
  const getAccInstapago = async () => {
    try {
      const entId = globalContext.currentEnterpriseId;
      const res = await accInstapago("GET",entId);
      if (res && res.data) {
        setListUsers(Object.values(res.data)); // Actualiza correctamente el estado
      }
    } catch (error) {
      console.error("Error obteniendo username", error);
    }
  };

  // useEffect para monitorear cambios en listUsers
  useEffect(() => {
    console.log(listUsers, "list username actualizada");
  }, [listUsers]); // Solo se ejecutará cuando `listUsers` cambie

  useEffect(() => {
    const hasAccess = verifyAccessRole(Routes, 49);
    if (!hasAccess || GlobalContext.parentEntId) {
      setRedirect(<Redirect to={`/admin/dashboard`} />);
    }
  }, [globalContext.parentEntId]);

  useEffect(() => {
    if (!globalContext.currentEnterpriseId) {
      setStep(0);
    } else {
      setStep(1);
      getAccInstapago();
    }
  }, [globalContext.currentEnterpriseId]);

  useEffect(() => {
    if (globalContext.currentEnterpriseId) {
      getAccInstapago();
    }
  }, [modalNewCredential, loading, selectedMarketplace]);
  return (
    <>
      <PedidosYaStyle.Header>
        <PedidosYaStyle.Text size="15pt" fw="500">
          Instapago configuración
        </PedidosYaStyle.Text>
      </PedidosYaStyle.Header>
      <OdooStyle>
        <div className="goBack d-flex justify-content-between mt-2">
          <div className="row w-50">
            <div className="col-1">
              <Link to="/admin/plugins/my-plugins/my-menu-plugins">
                <button className="btn-back">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
              </Link>
            </div>

            {selectedMarketplace ? (
              <div className="col-10">
                <Nav variant="pills" defaultActiveKey="configurar">
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => changeStep(1)}
                      eventKey={
                        loading || step === 0 ? "disabled" : "configurar"
                      }
                      disabled={loading || (step === 0 && true)}
                    >
                      Credenciales de {selectedMarketplace || "Instapago"}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => changeStep(2)}
                      eventKey={loading || step === 0 ? "disabled" : "app"}
                      disabled={loading || (step === 0 && true)}
                    >
                      Operaciones de {selectedMarketplace || "Instapago"}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            ) : (
              <p>No se ha seleccionado ninguna cuenta</p>
            )}
          </div>
          <div className="d-flex col-5 justify-content-end">
          <PedidosYaStyle.SelectWeb
              style={{ maxWidth: 280, height: 50 }}
              value={selectedMarketplace || ""}
              onChange={(e) => handleSubmit(e.target.value)}
              disabled={listUsers.length === 0}
            >
              <option value="" disabled>Seleccione una cuenta</option>
              {listUsers.map((user) => (
                <option key={user.store} value={user.store}>
                  {user.store}
                </option>
              ))}
          </PedidosYaStyle.SelectWeb>
            <div style={{ maxWidth: 250 }}>
              <Button
                width="100%"
                height="100%"
                className="d-flex w-100 mr-1"
                minWidth="0px"
                onClick={handleModal}
              >
                <div className="d-none d-xl-block">Nueva credencial&nbsp;</div>
                <div>
                  <FaPlus />
                </div>
              </Button>
            </div>
          </div>
        </div>
        {selectedMarketplace &&
          step > 0 &&
          selectedMarketplace !== null &&
          !modalNewCredential && (
            <View
              username={selectedMarketplace}
              step={step}
              listUsers={listUsers}
            />
          )}
      </OdooStyle>
      <InstapagoCredentials
            key={store}
            store={store}
            modalNewCredential={modalNewCredential}
            setModalNewCredential={setModalNewCredential}
            setLoading={setLoading}
            listUsers={listUsers}
          />
      {console.log(store)}
      {step > 0 && store && (
        <>
        {store && (
          <InstapagoCredentials
            key={store}
            store={store}
            modalNewCredential={modalNewCredential}
            setModalNewCredential={setModalNewCredential}
            setLoading={setLoading}
            listUsers={listUsers}
          />
        )}
        </>
    )}
    </>
  );
}
