import React from 'react';
import './instapagostStyle.css';

function ProductClientData({productData, saleOrderData, tasaBancaria}) {
    
    const truncateFixed = (num, decimals) => {
        const factor = Math.pow(10, decimals); // Calcula 10^decimals (10 elevado a los decimales deseados)
        return Math.trunc(num * factor) / factor; // Multiplica, trunca y luego divide para mantener los decimales
    };
    
    const orderDataProductsDetails = {
        subTotal: saleOrderData[0].amount_untaxed,
        iva: saleOrderData[0].amount_tax,
        totalAmount: saleOrderData[0].amount_total,
        missing: saleOrderData[0].amount_residual,
    }
    const roundUpSecondDecimal = (amount) => {
        return Math.ceil(amount * 100) / 100; // Redondea siempre hacia arriba en el segundo decimal
    }
    const amountTotalInBs = () =>{
        const value = orderDataProductsDetails.missing * tasaBancaria
        return truncateFixed(roundUpSecondDecimal(value) ,2)
    }
    const faltante = orderDataProductsDetails.missing !== orderDataProductsDetails.totalAmount;
  return (
    <div className='container'> 
        <h4 className='text-center mt-5 mb-2'>Detalles del producto</h4>
        <div className="row">
            <div className="col-12 px-0">
                <table className="table table-striped mb-2 " style={{borderBottom:'1px solid #b5b5b5'}}>
                    <thead>
                        <tr>
                        <th scope="col" className='d-none d-md-block'>#</th>
                        <th scope="col">Nombre del producto</th>
                        <th scope="col">Cantidad</th>
                        <th scope="col">Precio</th>
                        <th scope="col">SubTotal</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            productData.map((value, index)=>(
                                <tr key={index}>
                                    <th scope="row" className='d-none d-md-block'>{index+1}</th>
                                    <td>{value.product_id[1]}</td>
                                    <td>{value.quantity}</td>
                                    <td>{value.price_unit}</td>
                                    <td>{value.price_subtotal}</td>
                                </tr>
                            ))  
                        }
                    </tbody>
                </table>
                <div className="totalPrice w-full ml-auto" style={{maxWidth:180}}>
                    <ul className="price p-0" style={{listStyle:'none'}}>
                        <li className='text-end d-flex justify-content-between py-1 px-2' style={{textAlign:'end'}}><p className='m-0'>Subtotal:</p> {orderDataProductsDetails.subTotal}$</li>
                        <li className='text-end d-flex justify-content-between py-1 px-2' style={{textAlign:'end'}}><p className='m-0'>IVA:</p>{orderDataProductsDetails.iva}$</li>
                        <li className='text-end d-flex justify-content-between py-1 px-2' style={{textAlign:'end', backgroundColor: '#0000000d', fontSize:16}}><p className='m-0'>Monto total:</p> <span style={{fontWeight:'500'}}>{orderDataProductsDetails.totalAmount}$</span></li>
                        {
                          faltante && (
                            <li className='text-end d-flex justify-content-between py-1 px-2' style={{textAlign:'end', backgroundColor: '#0000000d', fontSize:17, fontWeight:'bold'}}><p className='m-0'>Faltante:</p> <span style={{fontWeight:'500'}}>{orderDataProductsDetails.missing}$</span></li>
                          ) 
                        }
                        
                        <li className='text-end d-flex justify-content-between py-1 px-2' style={{textAlign:'end', backgroundColor: '#0000000d', fontSize:19, fontWeight:'bold'}}><p className='m-0'>{faltante ? 'Faltante':'Total'}:</p> <span style={{fontWeight:'500'}}>{amountTotalInBs()}Bs </span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ProductClientData
