import React from 'react'

function PayNowOrder({numOrder, customerInfo}) {
    const getFirstLetterDNI = () => customerInfo.customer_doc.charAt(0);
    const getDNI = () => customerInfo?.customer_doc?.slice(1) ?? "";
    console.log()
  return (
    <div>
        <div className="container">
            <h4 className='text-center mb-4' style={{color:'#424242'}}>Datos de la factura {numOrder}</h4>
            <div className="row" style={{gap: 23}}>
                <div className="input-group col-12 col-md-5 px-0">
                    <span className="input-group-text">Orden</span>
                    <input type="text" className="form-control" disabled value={numOrder}/>
                </div>
                <div className="input-group col-12 col-md-6 px-0">
                    <span className="input-group-text">Nombre</span>
                    <input type="text" className="form-control" disabled value={customerInfo.customer_name}/>
                </div>
                <div className="input-group col-12 col-md-5 px-0">
                    <span className="input-group-text text-center justify-content-center" style={{minWidth:50}}>{getFirstLetterDNI()}</span>
                    <input type="text" className="form-control" disabled value={getDNI()}/>
                </div>
                <div className="input-group col-12 col-md-6 px-0">
                    <span className="input-group-text">Telefono</span>
                    <input type="text" className="form-control" disabled value={customerInfo.customer_tlf}/>
                </div>
                <div className="input-group col-12 col-md-11 px-0">
                    <span className="input-group-text">Dirección</span>
                    <input type="text" className="form-control" disabled value={customerInfo.customer_address}/>
                </div>
                <div className="col-12 col-lg-6"></div>
            </div>
        </div>
  </div>
  )
}

export default PayNowOrder