import React from 'react';
import './pop-up.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faExclamationTriangle  } from '@fortawesome/free-solid-svg-icons';

function PopUp({show, setShow, message, action, status}) {
    let icon = '';

    if(status === 'success')  icon = faCheck;
    if(status === 'error')  icon = faTimes;
    if(status === 'warning')  icon = faExclamationTriangle;
  return (
    <>
    <div className={`popUp d-flex flex-column justify-content-center align-items-center ${status}`}>
        <h4>{action}</h4>
        {
          message && (<p>{message}</p>)
        }
        <FontAwesomeIcon icon={icon} className={`icon ${status}`}/>
        
        <button className={`${status} close`} onClick={() => setShow(false)}>
            Aceptar
        </button>
    </div>
    </>
  )
}

export default PopUp