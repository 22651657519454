import { userRole } from "./SessionStorageNames";
import Routas from "../../Routes"
const verifyAccessRole = (Routes, nroMatrch) => {
        const dataRoute = Routas.find(e => e.idMatch === nroMatrch)?.rolAccess.includes(localStorage.getItem(userRole));
        const currentPath = window.location.pathname;
        if (currentPath === "/admin/pagos/instapago") {
            return true;
        }
        if(!dataRoute) {
            return false
        }else {
            return true
        }
}
export default verifyAccessRole;