import React, { useContext, useEffect, useState, useCallback, useMemo } from "react";
import PedidosYaStyle from "../../../../assets/css/components/admin/plugins/PedidosYaStyle";
import { BiHide, BiShow } from "react-icons/bi";
import { accInstapago } from "../../../../hooks/api/instapago";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import ListOfProductsToSetParams from "../../../../assets/css/components/admin/marketplace-to-marketplace/products/ListOfProductsToSetParams";
import axios from "axios";

export default function InstapagoCredentials({
  store,
  loading,
  setLoading,
  modalNewCredential,
  setModalNewCredential,
  listUsers,
}) {
  const globalContext = useContext(GlobalContext);
  const [showPass, setShowPass] = useState(false);

  const toggleShowPassword = useCallback(() => {
    setShowPass((prev) => !prev);
  }, []);

  const [credentials, setCredentials] = useState({
    store: "",
    keyId: "",
    publicKeyId: "",
  });

  // Encuentra el usuario seleccionado en la lista de `listUsers`
  const selectedUser = useMemo(() => listUsers?.find((user) => user.store === store), [listUsers, store]);

  useEffect(() => {
    if (selectedUser) {
      setCredentials({
        store: selectedUser.store || "",
        keyId: selectedUser.keyId || "",
        publicKeyId: selectedUser.publicKeyId || "",
      });
    }
  }, [selectedUser]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setCredentials((prevState) => ({
      ...prevState,
      [name]: value || "",
    }));
  }, []);

  const handleSubmitData = useCallback(async (save) => {
    // if (!store) return;
    setLoading(true);
    try {
      const entId = globalContext.currentEnterpriseId;
      await accInstapago("POST", entId, credentials);
      await globalContext.showModalWithMsg(`Se ha actualizado la credencial de la tienda ${store} correctamente.`);
    } catch (err) {
      console.error("Error al guardar credenciales de Instapago", err);
      await globalContext.showModalWithMsg(`Error al actualizar la credencial de la tienda ${store}.`);
      alert("Error al guardar credenciales de Instapago, verifica los campos y vuelve a intentarlo.");
    } finally {
      setLoading(false);
      if (!save) setModalNewCredential(false);
    }
  }, [store, credentials, globalContext, setLoading, setModalNewCredential]);
  
  return (
    <div style={{ marginTop: 55 }}>
      {!modalNewCredential ? (
        <>
          {
            store && (
              <PedidosYaStyle.ContForm style={{ marginTop: 55 }}>
                <PedidosYaStyle.HeaderForm>
                  <PedidosYaStyle.Text size="14pt" fw="500" cl="#898989">
                    Credenciales de Instapago ({store})
                  </PedidosYaStyle.Text>
                </PedidosYaStyle.HeaderForm>

                <PedidosYaStyle.BodyForm>
                  {["store", "keyId", "publicKeyId"].map((field, index) => (
                    <PedidosYaStyle.ContInputAndLabel
                      key={index}
                      w="50%"
                      colSpan="2"
                      style={{ margin: "auto", marginTop: index === 0 ? 30 : 10 }}
                    >
                      <PedidosYaStyle.Text size="12pt" style={{ textAlign: "center" }}>
                        <strong>{field === "store" ? "Tienda" : field}</strong>
                      </PedidosYaStyle.Text>
                      <PedidosYaStyle.ContIconNadInputPass>
                        <PedidosYaStyle.Input
                          style={{ width: "100%" }}
                          name={field}
                          type='text'
                          value={credentials[field] || ""}
                          disabled={field === "store" || loading}
                          placeholder={field}
                          autoComplete="off"
                          onChange={handleChange}
                          // style={field === "store" ? { backgroundColor: "#efefef" } : {}}
                        />
                        {field !== "store" && (
                          <PedidosYaStyle.BtnShowPass onClick={toggleShowPassword}>
                            {showPass ? <BiHide /> : <BiShow />}
                          </PedidosYaStyle.BtnShowPass>
                        )}
                      </PedidosYaStyle.ContIconNadInputPass>
                    </PedidosYaStyle.ContInputAndLabel>
                  ))}
                  <PedidosYaStyle.ContInputAndLabel w="50%" colSpan="2" style={{ margin: "auto", marginTop: 25 }}>
                    <PedidosYaStyle.ContInputButtonGroup marginBottom="5px">
                      <PedidosYaStyle.BtnSend
                        onClick={() => handleSubmitData(true)}
                        style={{ width: "100%", textAlign: "center" }}
                      >
                        {loading ? "Espere" : "Guardar Credencial"}
                      </PedidosYaStyle.BtnSend>
                    </PedidosYaStyle.ContInputButtonGroup>
                  </PedidosYaStyle.ContInputAndLabel>
                </PedidosYaStyle.BodyForm>
              </PedidosYaStyle.ContForm>
            )
          }
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: "100vh",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "#5c5c5c63",
          }}
        >
          <PedidosYaStyle.ContForm
            style={{
              position: "absolute",
              maxWidth: 700,
              margin: "auto",
              width: "100%",
            }}
          >
            <ListOfProductsToSetParams.Close
              style={{ top: 10, right: 10 }}
              onClick={() => setModalNewCredential(false)}
            />
            <PedidosYaStyle.HeaderForm>
              <PedidosYaStyle.Text size="14pt">Agregar nueva credencial para Instapago</PedidosYaStyle.Text>
            </PedidosYaStyle.HeaderForm>

            <PedidosYaStyle.BodyForm>
              {["store", "keyId", "publicKeyId"].map((field, index) => (
                <PedidosYaStyle.ContInputAndLabel
                  key={index}
                  w="50%"
                  colSpan="2"
                  style={{ margin: "auto", marginTop: index === 0 ? 30 : 10 }}
                >
                  <PedidosYaStyle.Text size="12pt" style={{ textAlign: "center" }}>
                    <strong>{field === "store" ? "Tienda" : field}</strong>
                  </PedidosYaStyle.Text>
                  <PedidosYaStyle.Input
                    name={field}
                    value={credentials[field] || ""}
                    placeholder={field}
                    onChange={handleChange}
                  />
                </PedidosYaStyle.ContInputAndLabel>
              ))}
              <PedidosYaStyle.ContInputAndLabel w="50%" colSpan="2" style={{ margin: "auto", marginTop: 25 }}>
                <PedidosYaStyle.ContInputButtonGroup marginBottom="5px">
                  <PedidosYaStyle.BtnSend
                    onClick={() => handleSubmitData(false)}
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    {loading ? "Espere" : "Guardar nueva Credencial"}
                  </PedidosYaStyle.BtnSend>
                </PedidosYaStyle.ContInputButtonGroup>
              </PedidosYaStyle.ContInputAndLabel>
            </PedidosYaStyle.BodyForm>
          </PedidosYaStyle.ContForm>
        </div>
      )}
    </div>
  );
}
