import React, { useState } from 'react';
import './instapagostStyle.css';

function PayNowData({amount, paymentInfo, payQr, payType, setPayType}) {
  return (
    <div>
        <div className="container">
            <div>
                <h4 className='text-center'>Seleccione su metodo de pago</h4>

                <div className='d-flex justify-content-center mt-2' style={{gap:30}}>
                    <button className='btn btn-warning' onClick={()=>setPayType('mobilePayment')}>Pago movil</button>
                    <button className='btn btn-success' onClick={()=>setPayType('transfer')}>Transferencia</button>
                </div>
            </div>
            {
                !payType && (
                    <div className='separator pay-now-data'></div>
                )
            }
            {
                payType === 'mobilePayment' && (
                    <div className="row my-5" style={{gap: 23}}>
                        <div className="input-group col-12 col-md-7 px-0">
                            <span className="input-group-text">Banco</span>
                            <input type="text" className="form-control" disabled value={`0${paymentInfo.serialNumber} | ${paymentInfo.name}`}/>
                        </div>
                        <div className="input-group col-12 col-md-4 px-0">
                            <span className="input-group-text">Teléfono</span>
                            <input type="text" className="form-control" disabled value={paymentInfo.telephone}/>
                        </div>
                        <div className="input-group col-12 col-md-5 px-0">
                            <span className="input-group-text">Rif</span>
                            <input type="text" className="form-control" disabled value={paymentInfo.rif}/>
                        </div>
                        <div className="input-group col-12 col-md-6 px-0">
                            <span className="input-group-text">Monto</span>
                            <input type="text" className="form-control" disabled value={amount}/>
                        </div>
                        <div className="col-12 col-lg- d-flex justify-content-center align-items-center mb-4">
                            <img src={payQr?.qrCode} alt="" className='w-100 h-100' style={{maxWidth:300}} />
                        </div>
                    </div>
                ) 
            }
            {
                payType === 'transfer' && (
                    <div className="row my-5" style={{gap: 23}}>
                        <div className="input-group col-12 col-md-7 px-0">
                            <span className="input-group-text">Numero de cuenta</span>
                            <input type="text" className="form-control" disabled value={paymentInfo.bankAccount}/>
                        </div>
                        <div className="input-group col-12 col-md-7 px-0">
                            <span className="input-group-text">Banco</span>
                            <input type="text" className="form-control" disabled value={`${paymentInfo.name}`}/>
                        </div>
                        <div className="input-group col-12 col-md-5 px-0">
                            <span className="input-group-text">Rif</span>
                            <input type="text" className="form-control" disabled value={paymentInfo.rif}/>
                        </div>
                        <div className="input-group col-12 col-md-6 px-0">
                            <span className="input-group-text">Monto</span>
                            <input type="text" className="form-control" disabled value={amount}/>
                        </div>
                    </div>
                )
            }
            
            
        </div>
  </div>
  )
}

export default PayNowData