import React, { useEffect, useState } from 'react';
import PayNowOrder from '../admin/productos/pagos/PayNowOrder';
import PayNowProduct from '../admin/productos/pagos/PayNowProduct';
import PayNowData from '../admin/productos/pagos/PayNowData';
import { generateQrPay, getOrderData, getPaymentInfoClient, verifyMobilePayment } from '../../hooks/api/instapago';
import ValidateMobilePayment from '../admin/productos/pagos/ValidateMobilePayment';
import '../admin/productos/pagos/instapagostStyle.css';
import PopUp from '../general/popUps/PopUp';
import ThanksForPay from '../admin/productos/pagos/ThanksForPay';
import { consolidationWithOdoo } from '../../hooks/api/odoo';

function ClientPay() {
  const [step, setStep] = useState(1)
  const [orderData, setOrderData] = useState([])
  const [loading, setLoading] = useState(false)
  const [linkActive, setLinkActive] = useState(null)
  const [tasaBancaria, setTasaBancaria] = useState(0)
  const [amount, setAmount] = useState(0)
  const [payQr, setPayQr] = useState({})
  const [paymentInfo, setPaymentInfo] = useState(null)
  const [paymentDataPay, setPaymentDataPay] = useState({})
  const [bankValue, setBankValue] = useState(null);
  const [payType, setPayType] = useState(null)
  const [docType, setDocType] = useState(null);
  const [showPopUp, setShowPopUp] = useState(false)
  const [popUpData, setPopUpData] = useState({message: "", action: "", status: "" });

  const handleTitle = ['Información del Cliente', 'Detalles del Pago', 'Registrar el Pago'];
  const handleInfo = [
    'Por favor, asegúrate de que toda la información sea correcta. Si es así, presiona "Siguiente". En caso contrario, comunícate con el vendedor para realizar las correcciones necesarias.',
    'Verifica que el monto a pagar coincida exactamente con el valor indicado. Una vez realizado el pago, presiona "Siguiente" para continuar.',
    'Completa los siguientes campos con los detalles de tu pago para poder validar correctamente.'
  ];
  //************************************************* */
  const currentUrl = window.location.href; //Obtener url

  //Obtener id de la factura
  let numOrder = null;
  let entId = null;
  const match = currentUrl.match(/pagar-ahora\/([^\/]+\/\d{4}\/\d{2}\/\d+)\/entId\/([^\/]+)/);
      
  if (match) {
      numOrder = match[1];
      entId = match[2];
  } else {
      console.log("No se pudo extraer la información.");
  }
  const loadOrderData = async () =>{
    setLoading(true);
    if(!numOrder){
      setLoading(false)
      return
    }
    try {
      const orderidParser = numOrder.replaceAll("/", "-")
      const getData = await getOrderData(orderidParser);
      if (!getData) {
        alert('No se pudo obtener datos de la orden');
        setLoading(false);
        return;
      }
      setOrderData(getData.orderData);
      setLinkActive(getData.linkActive)
      setTasaBancaria(getData.tazaCambio)

      if(getData.linkActive){
        const payQr = await generateQrPay(entId ,orderidParser, {bank: '0134', amount: getData.orderData.bill_info.billTotalBs.toFixed(2).toString()})
        setPayQr(payQr)
        setAmount(getData.orderData.bill_info.billTotalBs.toFixed(2))
        const getPaymentInfo = (await getPaymentInfoClient()).bankInfos[0]
        setPaymentInfo(getPaymentInfo)
      }
      
    } catch (error) {
      console.error('Error al obtener datos:', error);
    } finally {
      setLoading(false);
    }
  }
  //Ir al siguiente paso
  const nextStep = () =>{
    setStep(step + 1)
  }
  //Retroceder un paso
  const backStep = () =>{
    setStep(step - 1)
  }

  //Limpia el text y lo preformatea para enviarlo al back
  const formatPhoneNumber = (phone) =>{
    let str = phone.toString().replace(/^0+/, ''); // Elimina todos los ceros al inicio
    return str.startsWith('4') ? str : '4' + str;   // Asegura que empiece con '4'
  }
  //Verificar pago
  const verifyMobilePaymentFn = async () =>{
    const odooJournalId = paymentInfo.odooJournalId;
    if(step === 3){
      const orderidParser = numOrder.replaceAll("/", "-")
      const banksValue = {
        ...paymentDataPay,  
        bank: bankValue, 
        clientId: `${docType.value}${paymentDataPay.clientId}`, 
        ordernumber: numOrder,
        phonenumberclient: '0058'+formatPhoneNumber(paymentDataPay.phonenumberclient)
      }
      const verifyMobilePaymentValue= await verifyMobilePayment(entId, orderidParser, banksValue, payType)
     
      if(verifyMobilePaymentValue.data.code === "401"){
        const data = {
          "billNumber": numOrder,
          "amount": Number(paymentDataPay.amount),
          "journal_id": odooJournalId,
          "payRef": paymentDataPay.reference
        }
        await consolidationWithOdoo(data)
        setStep(4)
      }else{
        setShowPopUp(true)
        setPopUpData({
          message: verifyMobilePaymentValue.message,
          action: 'Ha ocurrido un error', 
          status: 'error'
        })
      }
    }
    
  }
  useEffect(()=>{
    loadOrderData()
  },[])
  return (
    <>

      {
        showPopUp && (
          <PopUp setShow={setShowPopUp} message={popUpData.message} action={popUpData.action} status={popUpData.status} />
        )
      }
        <div className='w-100' style={{backgroundColor:'#014788', padding: '.7rem'}}>
          <img src='https://212global.com/wp-content/uploads/2020/08/Logos-de-212.png' width={100}></img>
        </div>
        {
          linkActive && step < 4 && (
              <>
              <h1 className='text-center mt-4 mb-4'>{handleTitle[step - 1]}</h1>
              <p className='text-center font-weight-bold mx-auto' style={{maxWidth:750, width:'95%'}}>{handleInfo[step - 1]}</p>

              <div className='d-flex justify-content-center align-items-center process-pay' style={{gap: 80}}>
                  <p className='active'>Informacion</p>
                  <p className={`${step > 1 && 'active'}`}>Pagar</p>
                  <p className={`${step > 2 && 'active'}`}>Verificar</p>
              </div>
              {
                  loading && (
                    <div className='col-12 d-flex justify-content-center align-items-center' style={{height: 'calc(100vh - 400px)'}}>
                      <div className="loader"></div>
                    </div>
                  )
              }
              {Object.keys(orderData).length > 0 && !loading && (
                  <div className="mx-auto p-4 mb-4 d-flex flex-column align-items-center" style={{maxWidth:720, width:'95%', border: '1px solid #cecece', borderRadius:'.5rem'}}>
                    {step === 1 && (
                      <div>
                        <PayNowOrder numOrder={numOrder} customerInfo={orderData.customer_info}/>
                        <PayNowProduct productData={orderData.order_info} saleOrderData={orderData.bill_info} tasaBancaria={tasaBancaria} />
                      </div>
                    )}
                    {step === 2 && (
                      <div>
                        <PayNowData amount ={amount} paymentInfo={paymentInfo} payQr={payQr} payType={payType} setPayType={setPayType}/>
                      </div>
                    )}
                    {step === 3 && (
                      <div>
                        <ValidateMobilePayment amount={amount} setPaymentDataPay={setPaymentDataPay} setBankValue={setBankValue} payType={payType} docType={docType} setDocType={setDocType}/>
                      </div>
                    )}

                    <div className='d-flex' style={{gap:25}}>
                      {
                        step > 1 && step < 4 && (
                        <button type="button" className="btn btn-warning" onClick={()=>backStep()}>Regresar</button>
                      )}
                      {
                        step < 3 && (
                          <button type="button" className="btn text-white" style={{backgroundColor: '#014788'}} onClick={()=>nextStep()} disabled={!payType && step > 1}>Siguiente</button>
                        )
                      }
                      {
                        step === 3 && (
                          <button type="button" className="btn btn-success" disabled={step < 3 } onClick={()=>verifyMobilePaymentFn()}>Aceptar</button>
                        )
                      }
                    </div>
                  </div>
                ) 
              }
              </>
          )
        }
        {step === 4 && (
          <div>
            <ThanksForPay/>
          </div>
        )}

        {
          linkActive === false && (
            <div className='d-flex flex-column align-items-center justify-content-center mt-5 container-error'>
              <h1>El enlace de pago ha caducado</h1>
              <p>Por favor solicite otro </p>
              <img className='uix-error-vector' src={require('../../assets/img/jpeg/error-uix.jpeg')}/>
            </div>
          )
        }
    </>
  )
}

export default ClientPay