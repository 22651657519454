import React, { useContext, useEffect, useState } from "react";
import PedidosYaStyle from "../../../../assets/css/components/admin/plugins/PedidosYaStyle";
import { Select } from "../../../../assets/css/components/admin/products/ShowProductInfo/ShowProductInfo.css";
import handleAxiosError from "../../update-and-publish/functions/handleAxiosError";
import axios from "axios";
import {
  keyAccessToken,
  keyUserId,
} from "../../../../assets/js/SessionStorageNames";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import { downloadCategoriesVtex, syncProductsVtex, updateProductsVtex } from "../../../../hooks/api/vtex";
import InstapagoCredentials from "./InstapagoCredentials";

export default function View({ username, step = 2, listUsers }) {
  const [loading, setLoading] = useState(false);
  const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
  const [tarifas, setTarifas] = useState([]);
  const [tarifaSelected, setTarifaSelected] = useState(null);
  const globalContext = useContext(GlobalContext);
  const [skuId, setSkuId] = useState(null);

  const changeTarifa = (t) => {
    setTarifaSelected(t);
    console.log(t);
  };


  const donwloadCategories = async () => {
    try {
      const entId = globalContext.currentEnterpriseId;
      console.log('username')
      console.log(entId)
      console.log(username)
      console.log('username')
      const resp = await downloadCategoriesVtex(entId, username)
      console.log('se ejecuto', resp)
      if(resp?.data?.message) await globalContext.showModalWithMsg('Se ha iniciado la descarga de categorias en segundo plano.');
    } catch (error) {
      console.log(error);
      await globalContext.showModalWithMsg('Ha ocurrido un error al descargar las productos.');
    }
}

  const syncProducts = async () => {
    try {
      const entId = globalContext.currentEnterpriseId;
      const resp = await syncProductsVtex(entId, username)
      if(resp?.message) await globalContext.showModalWithMsg('Se ha iniciado el proceso de sincronización de productos en segundo plano.');
    } catch (error) {
      console.log(error);
      await globalContext.showModalWithMsg('Ha ocurrido un error al sincronizar los productos.');
    }
  }
  const updateProducts = async () => {
    try {
      const entId = globalContext.currentEnterpriseId;
      console.log(skuId, 'sku')
      const resp = await updateProductsVtex(entId, username, skuId)
      if(resp?.message) await globalContext.showModalWithMsg('Se ha iniciado el proceso de sincronización de productos en segundo plano.');
    } catch (error) {
      console.log(error);
      await globalContext.showModalWithMsg('Ha ocurrido un error al sincronizar los productos.');
    }
  }
  /**
   * Function to consult the lists of available rates
   */
  async function getPriceList() {
    const entId = await globalContext.currentEnterpriseId;
    const url = `${process.env.REACT_APP_BACKEND_URL}/v1/odoo-pricelist/${entId}`;
    if (entId)
      await axios
        .get(url, {
          cancelToken: axiosCancelTokenSource.token,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        })
        .then((resp) => {
          const data = resp.data.data;
          setTarifas(data.priceLists);
          console.log(data.priceLists);
        })
        .catch((err) => {
          console.log(err);
          handleAxiosError(
            err,
            "Disculpe, no se pudo cargar la lista de tarifas."
          );
        });
  }

  useEffect(() => {
    getPriceList();
  }, [globalContext.currentEnterpriseId]);

  return (
    <>
      {step === 2 ? (
        <>
          <PedidosYaStyle.ContForm style={{ marginTop: 55 }}>
            <PedidosYaStyle.HeaderForm>
              <PedidosYaStyle.Text size="14pt" fw="500" cl="#898989">
                Operaciones de Instapago ({username})
              </PedidosYaStyle.Text>
            </PedidosYaStyle.HeaderForm>

            <PedidosYaStyle.BodyForm>
              {/* <div style={{ display: "flex", justifyContent: "center" }}>
                <PedidosYaStyle.ContInputAndLabel
                  w="50%"
                  colSpan="1"
                  style={{ marginTop: 40 }}
                >
                  <PedidosYaStyle.Text
                    size="12pt"
                    style={{ textAlign: "center" }}
                  >
                    <strong>Tarifas Disponibles</strong>
                  </PedidosYaStyle.Text>
                  <div className="d-flex justify-content-center">
                    <Select
                      className="tarifa"
                      onChange={(e) => {
                        changeTarifa(e.target.value);
                      }}
                    >
                      <option selected>SELECCIONE UNA TARIFA</option>
                      {tarifas.map((val, i) => (
                        <option key={i} name="tarifa" value={val.name}>
                          {val.name}
                        </option>
                      ))}
                    </Select>
                    <PedidosYaStyle.BtnSend
                      //   onClick={}
                      style={{ width: "100%", textAlign: "center" }}
                    >
                      {loading ? "Espere" : "Actualizar"}
                    </PedidosYaStyle.BtnSend>
                  </div>
                </PedidosYaStyle.ContInputAndLabel>
              </div> */}
              <PedidosYaStyle.ContInputAndLabel
                w="50%"
                colSpan="2"
                style={{ margin: "auto", marginTop: 30 }}
              >
                <PedidosYaStyle.Text
                  size="12pt"
                  style={{ textAlign: "center" }}
                >
                  <strong>Sincronizar</strong>
                </PedidosYaStyle.Text>
                <PedidosYaStyle.ContInputButtonGroup marginBottom="5px">
                  <PedidosYaStyle.BtnSend
                    onClick={syncProducts}
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    {loading ? "Espere" : "Sincronizar todos los productos"}
                  </PedidosYaStyle.BtnSend>
                </PedidosYaStyle.ContInputButtonGroup>
              </PedidosYaStyle.ContInputAndLabel>
              {/* <PedidosYaStyle.ContInputAndLabel
                w="50%"
                colSpan="2"
                style={{ margin: "auto", marginTop: 30 }}
              >
                <PedidosYaStyle.Text
                  size="12pt"
                  style={{ textAlign: "center" }}
                >
                  <strong>Actualizar productos por SkuId</strong>
                </PedidosYaStyle.Text>
                <PedidosYaStyle.ContInputButtonGroup marginBottom="5px">
                  <PedidosYaStyle.Input value={skuId}
                  onChange={(v)=>setSkuId(v.target.value)}
                  />
                  <PedidosYaStyle.BtnSend
                    onClick={updateProducts}
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    {loading ? "Espere" : "Actualizar productos"}
                  </PedidosYaStyle.BtnSend>
                </PedidosYaStyle.ContInputButtonGroup>
              </PedidosYaStyle.ContInputAndLabel> */}

              <PedidosYaStyle.ContInputAndLabel
                w="50%"
                colSpan="2"
                style={{ margin: "auto", marginTop: 25 }}
              >
                <PedidosYaStyle.Text
                  size="12pt"
                  style={{ textAlign: "center" }}
                >
                </PedidosYaStyle.Text>
                <PedidosYaStyle.ContInputButtonGroup marginBottom="5px">
                  <PedidosYaStyle.BtnSend
                    onClick={donwloadCategories}
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    {loading ? "Espere" : "Descargar Categorias"}
                  </PedidosYaStyle.BtnSend>
                </PedidosYaStyle.ContInputButtonGroup>
              </PedidosYaStyle.ContInputAndLabel>
            </PedidosYaStyle.BodyForm>
          </PedidosYaStyle.ContForm>
        </>
      ) : (
        <>
          <InstapagoCredentials username={username} loading={loading} setLoading={setLoading} listUsers={listUsers} />
        </>
      )}
    </>
  );
}
